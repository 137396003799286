export enum enteteTableauDetailSignatairesEnum {
	lastName = 'Nom',
	firstName = 'Prénom',
	mobilePhone = 'Téléphone',
	email = 'Courriel',
	status = 'Statut',
	proof = 'Preuve',
	type = 'Type'
}

export enum enteteTableauDetailSuiveursEnum {
	lastName = 'Nom',
	firstName = 'Prénom',
	email = 'Courriel'
}

export enum enteteTableauDetailDocumentsEnum {
	id = 'id',
	name = 'name'
}

// Statuts des signataires
export enum statutSignataireEnum {
	pending = 'En attente',
	signed = 'Signé'
}