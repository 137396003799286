export interface PopinModel {
	id: string
	open: boolean
}

export const INITIAL_AJOUT_SIGNATAIRE_POPIN: PopinModel = {
	id: 'ajout_signataire',
	open: false
}

export const INITIAL_AJOUT_SUIVEUR_POPIN: PopinModel = {
	id: 'ajout_suiveur',
	open: false
}

export const INITIAL_RETOUR_CREATION_POPIN: PopinModel = {
	id: 'retour_creation',
	open: false
}

export enum ongletsAjoutSignataire {
	interne = 'interne',
	externe = 'externe'
}

export enum typeSignataire {
	Signataire = 'Signataire',
	Validateur = 'Validateur'
}