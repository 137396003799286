import { HeadRow } from '../types/tableModel'
import { tableTypeEnum } from '../enums/tableTypeEnum'
import { formatDate } from '../../../utils/formatDate'

// Gestion de la valeur de la ligne du tableau (par exemple si c'est une date, on affiche une date au bon format)
const gestionValeurLigne = (valeur: any, typeEntete?: tableTypeEnum) => {
	if (typeEntete === tableTypeEnum.date && valeur) {
		return formatDate(new Date(valeur))
	}

	return valeur
}

// Remplissage du tableau selon la valeur de la liste passée en paramètre et la liste des colonnes (les clés des listes doivent correspondre aux IDs des colonnes)
export const remplissageLigneSelonEnteteTableau = <ListModel>(listeMappe: ListModel[], entete: HeadRow[]) => listeMappe.map(
	(elementListe) => (
		entete.reduce(
			(acc, enteteTableau) => (
				{
					...acc,
					[enteteTableau.id]: enteteTableau.render ?
						enteteTableau.render(enteteTableau.getFullItem ? elementListe : elementListe[enteteTableau.id as keyof typeof elementListe])
						:
						gestionValeurLigne(elementListe[enteteTableau.id as keyof typeof elementListe], enteteTableau.type)
				}
			), {})
	)
)
