import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './containers/App'
import './font/montserrat/montserrat.css'
import './font/blackerdisplay/blackerdisplay.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ListeProceduresProvider } from './modules/listeProcedures/context/ListeProceduresContext'

const root = ReactDOM.createRoot(document.getElementById('root')!)
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

root.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<ListeProceduresProvider>
				<App />
			</ListeProceduresProvider>
		</QueryClientProvider>
	</BrowserRouter>
)