enum liens {
	login = '/auth/login',
	token = '/auth/token',
	validate = '/auth/validate',
	listeProcedure = '/procedures/liste',
	creationProcedure = '/procedure/creation',
	detailProcedure = '/procedure/detail',
	uuid = '/api/procedure/uuid',
	ajoutFichier = '/api/procedure/addFile',
	suppressionFichier = '/api/procedure/removeFile',
	creerProcedure = '/api/procedure/create',
	annulerProcedure = '/api/procedure/cancel',
	telechargementFichier = '/api/procedure/getFile',
	telechargementPreuve = '/api/procedure/getProofFile',
	listeUtilisateurokta = '/api/okta/user'
}

export default liens