import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgFlecheVersBas = (
	{
		color,
		width = 14,
		height = 20,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd"
		      d="M7.06261 19.6328C6.88131 19.6328 6.70743 19.5608 6.57924 19.4326C6.45104 19.3044 6.37902 19.1305 6.37902 18.9492L6.37902 2.82598L2.07784 7.12851C1.94948 7.25688 1.77539 7.32899 1.59386 7.32899C1.41233 7.32899 1.23823 7.25688 1.10987 7.12851C0.981512 7.00015 0.9094 6.82606 0.9094 6.64453C0.9094 6.463 0.981512 6.28891 1.10987 6.16055L6.57863 0.691795C6.64213 0.628134 6.71756 0.577627 6.80061 0.543165C6.88366 0.508703 6.97269 0.490965 7.06261 0.490965C7.15253 0.490965 7.24156 0.508703 7.32461 0.543165C7.40766 0.577627 7.4831 0.628134 7.5466 0.691795L13.0153 6.16055C13.1437 6.28891 13.2158 6.463 13.2158 6.64453C13.2158 6.82606 13.1437 7.00015 13.0153 7.12851C12.887 7.25688 12.7129 7.32899 12.5314 7.32899C12.3498 7.32899 12.1757 7.25688 12.0474 7.12851L7.7462 2.82598L7.7462 18.9492C7.7462 19.1305 7.67418 19.3044 7.54598 19.4326C7.41778 19.5608 7.24391 19.6328 7.06261 19.6328Z"
		      fill={color} />
	</svg>

)
export default SvgFlecheVersBas