import axios, { AxiosResponse } from 'axios'
import liens from '../../enum/liens'
import localStorageEnum from '../../enum/localstorageEnum'
import { FetchTokenModel } from '../types/fetchTokenModel'
import { FetchMethodEnum } from '../../enum/fetchMethodEnum'

export const fetchToken = (): Promise<FetchTokenModel> =>
	axios(liens.token)
		.then((res) => {
			localStorage.setItem(localStorageEnum.accessToken, res.data.token)
			localStorage.setItem(localStorageEnum.informationsUtilisateur, JSON.stringify(res.data))
			return res.data
		})
		.catch(fetchValidation)

export const fetchValidation = (): Promise<AxiosResponse<void> | void> =>
	axios({ method: FetchMethodEnum.post, url: liens.validate })
		.then((res) => {
			if (!res.data) {
				localStorage.removeItem(localStorageEnum.accessToken)
				window.location.pathname = liens.login
			}
		})
		.catch(() => {
			localStorage.removeItem(localStorageEnum.accessToken)
			window.location.pathname = liens.login
		})
