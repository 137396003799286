import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgUpload = (
	{
		color,
		width = 42,
		height = 42,
		...props
	}: IconsModel
) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42 42" {...props}>
		<g id="Groupe_277" data-name="Groupe 277" transform="translate(-232 -667.373)">
			<g id="Tracé_143" data-name="Tracé 143" transform="translate(232 667.373)" fill="none">
				<path d="M21,0A21,21,0,1,1,0,21,21,21,0,0,1,21,0Z" stroke="none" />
				<path
					d="M 21 2 C 15.92491912841797 2 11.15360069274902 3.976341247558594 7.564968109130859 7.564968109130859 C 3.976341247558594 11.15360069274902 2 15.92491912841797 2 21 C 2 26.07508087158203 3.976341247558594 30.84640121459961 7.564968109130859 34.43502807617188 C 11.15360069274902 38.02365875244141 15.92491912841797 40 21 40 C 26.07508087158203 40 30.84640121459961 38.02365875244141 34.43502807617188 34.43502807617188 C 38.02365875244141 30.84640121459961 40 26.07508087158203 40 21 C 40 15.92491912841797 38.02365875244141 11.15360069274902 34.43502807617188 7.564968109130859 C 30.84640121459961 3.976341247558594 26.07508087158203 2 21 2 M 21 0 C 32.59798049926758 0 42 9.402019500732422 42 21 C 42 32.59798049926758 32.59798049926758 42 21 42 C 9.402019500732422 42 0 32.59798049926758 0 21 C 0 9.402019500732422 9.402019500732422 0 21 0 Z"
					stroke="none" fill={color} />
			</g>
			<path id="u15" d="M415.349,1916v12.6h2.036V1916l5.581,5.581,1.44-1.44-8.038-8.039-8.038,8.039,1.44,1.44Z" transform="translate(-163.367 -1231.977)" fill={color} />
		</g>
	</svg>
)

export default SvgUpload
