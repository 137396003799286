import { CreationProcedureModel, SignataireListeModel, SignataireModel, SuiveurListeModel, SuiveurModel } from '../types/CreationProcedureModel'
import { champsSignataireEnum, champsSuiveurEnum } from '../components/popinAjoutSignataire/enums/champsSignataireEnum'

export const mapperSignatairesPourListe = (signataires: SignataireModel[]): SignataireListeModel[] => {
	return signataires.map((valeur, index) => (
		{
			[champsSignataireEnum.nom]: valeur[champsSignataireEnum.nom],
			[champsSignataireEnum.prenom]: valeur[champsSignataireEnum.prenom],
			[champsSignataireEnum.telephone]: valeur[champsSignataireEnum.telephone],
			[champsSignataireEnum.courriel]: valeur[champsSignataireEnum.courriel],
			[champsSignataireEnum.type]: valeur[champsSignataireEnum.type],
			ordre: index + 1,
			supprimer: index
		}
	))
}

export const mapperSuiveursPourListe = (suiveurs: SuiveurModel[]): SuiveurListeModel[] => {
	return suiveurs.map((valeur, index) => (
		{
			[champsSuiveurEnum.nom]: valeur[champsSignataireEnum.nom],
			[champsSuiveurEnum.prenom]: valeur[champsSignataireEnum.prenom],
			[champsSuiveurEnum.courriel]: valeur[champsSignataireEnum.courriel],
			supprimer: index
		}
	))
}


export const echangerPositionSignataire = (setValues: (formValues: CreationProcedureModel) => void, tableau: CreationProcedureModel, indexA: number, indexB: number): void => {
	const tmpValeurs = tableau
	const temp = tmpValeurs.signers[indexA]
	tmpValeurs.signers[indexA] = tmpValeurs.signers[indexB]
	tmpValeurs.signers[indexB] = temp
	setValues({ ...tmpValeurs, signers: [...tmpValeurs.signers] })
}

export const mapperFormulaireCreationProcedurePourBack = (tableau: CreationProcedureModel) => {

}