import { gecinaBlueMain } from '../../../theme'
import { Box, Grid } from '@mui/material'
import React, { Dispatch, useContext } from 'react'
import CustomSwitch from '../../../components/switch/CustomSwitch'
import FiltresListeProcedures from './FiltresListeProcedures'
import localStorageEnum from '../../../enum/localstorageEnum'
import { INITIAL_TOKEN } from '../../../containers/types/fetchTokenModel'
import { ListeProceduresContext } from '../context/ListeProceduresContext'
import { filtresListeProceduresPostEnum } from '../enums/listeProceduresEnum'

interface EnteteListeProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>,
	rafraichir: boolean
}

const EnteteListe: React.FC<EnteteListeProps> = (
	{
		setLoading,
		rafraichir
	}
) => {
	const { donneesFormulaire, setDonneesFormulaire } = useContext(ListeProceduresContext)

	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: '192px', position: 'sticky', top: '80px', paddingX: '172px' }}>
			<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
				<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
					Liste des procédures
				</Box>

				{
					// On check s'il y a les informations de l'utilisateur dans le localStorage,
					// Si ce n'est pas le cas, on utilise le model par défaut avec la valeur "false" pour "is_admin"
					JSON.parse(localStorage.getItem(localStorageEnum.informationsUtilisateur) || JSON.stringify(INITIAL_TOKEN)).isAdmin && (
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Box sx={{ fontSize: 14, fontWeight: 600, mr: '20px' }}>
								Afficher toutes les procédures
							</Box>

							<CustomSwitch
								onChange={(event) => setDonneesFormulaire({
									...donneesFormulaire,
									[filtresListeProceduresPostEnum.seeAll]: event.target.checked
								})}
								checked={donneesFormulaire[filtresListeProceduresPostEnum.seeAll] ?? false}
							/>
						</Box>
					)
				}
			</Grid>

			{/* Composant gérant les filtres sur la liste des procédures */}
			<Grid container item xs={12} justifyContent="space-evenly" sx={{ pt: 5 }}>
				<FiltresListeProcedures  rafraichir={rafraichir} setLoading={setLoading} />
			</Grid>
		</Box>
	)
}

export default EnteteListe
