import React, { useState } from 'react'
import { Box, Tooltip, useTheme } from '@mui/material'
import { gecinaBlueMain, gecinaBrownMain, WHITE } from '../../theme'
import { useNavigate } from 'react-router-dom'
import liens from '../../enum/liens'
import { fetchUuid } from '../../modules/creationProcedure/api/creationProcadureApi'
import SvgProfile from '../../icons/SvgProfile'


interface NavBarData {
	name: string
}

type NavBarProps = NavBarData

const NavBar: React.FC<NavBarProps> = (props) => {
	const navigate = useNavigate()

	const path = location.pathname
	const [openVersionNumber, setOpenVersionNumber] = useState<boolean>(false)

	return (
		<Box sx={{ bgcolor: WHITE, width: '100%', minHeight: '80px', display: 'flex', position: 'sticky', top: 0, zIndex: 1 }}>
			<Box
				onClick={() => navigate(liens.listeProcedure, { state: { rafraichir: true } })}
				sx={{ mx: '30px', alignSelf: 'center', cursor: 'pointer' }}
				onMouseEnter={() => setOpenVersionNumber(true)}
				onMouseLeave={() => setOpenVersionNumber(false)}
			>
				<Tooltip
					title={
						<>
							<span>v1.0.10</span>
						</>
					}
					open={openVersionNumber}>
					<img src={'/img/logo_gecina.png'} height={50}>
					</img>
				</Tooltip>
			</Box>
			<Box sx={{ alignSelf: 'center', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 700, display: 'flex', flexDirection: 'row', height: '100%' }}>
				<Box onClick={() => navigate(liens.listeProcedure, { state: { rafraichir: true } })}
				     sx={{
					     marginLeft: '40px', color: gecinaBlueMain, height: '100%', position: 'relative',
					     '&:hover': {
						     cursor: 'pointer'
					     },
					     '&:active': {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '45px',
							     width: '100%',
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     right: 0
						     }
					     },
					     ...(path.includes(liens.listeProcedure) && {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '45px',
							     width: '100%',
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     right: 0
						     }
					     })
				     }}>
					Liste des procédures
				</Box>
				<Box onClick={() => {
					fetchUuid()
						.then((res) => {
							const localUuid = res.uuid
							localStorage.setItem('uuid', localUuid!)
							navigate(liens.creationProcedure)
						})
				}}
				     sx={{
					     marginLeft: '40px', color: gecinaBlueMain, height: '100%', position: 'relative',
					     '&:hover': {
						     cursor: 'pointer'
					     },
					     '&:active': {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '45px',
							     width: '100%',
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     right: 0
						     }
					     },
					     ...(path.includes(liens.creationProcedure) && {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '45px',
							     width: '100%',
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     right: 0
						     }
					     })
				     }}>
					Créer une procédure
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', position: 'absolute', right: '85px' }}>
				<Box>
					<SvgProfile color={gecinaBlueMain} height={30} width={30} />
				</Box>
				<Box sx={{ fontFamily: 'Montserrat', fontSize: '16px', marginLeft: '15px', alignSelf: 'center', color: gecinaBlueMain }}>
					{props.name}
				</Box>
			</Box>
		</Box>
	)
}

export default NavBar
