import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import Dialog from '../../../../components/dialog/Dialog'
import { Autocomplete, Box, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import { champsSignataireEnum, champsSuiveurEnum } from './enums/champsSignataireEnum'
import { SuiveurModel, SuiveurRechercheModel } from '../../types/CreationProcedureModel'
import TextField from '../../../../components/fields/FormTextField'
import { gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../../theme'
import Button from '../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../components/button/enums/buttonTypesEnum'
import { INITIAL_VALEURS_FORMULAIRE, INITIAL_VALEURS_FORMULAIRE_SUIVEUR } from './types/ajoutSignataireModel'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { CreationProcedureContext } from '../../CreationProcedure'
import { listeUtilisateurokta } from '../../api/creationProcadureApi'
import SvgClose from '../../../../icons/SvgClose'
import SvgRecherche from '../../../../icons/SvgRecherche'

type PopinAjoutSuiveurProps = DialogCommonProps

const PopinAjoutSuiveur: React.FC<PopinAjoutSuiveurProps> = ({ onCloseDialog, open }) => {
	const methods = useForm<SuiveurRechercheModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE_SUIVEUR
	})
	const { register, handleSubmit, reset, formState: { errors }, getValues, setValue, watch } = methods
	const { setFormValues, formValues } = useContext(CreationProcedureContext)

	// Liste des utilisateurs affiché dans l'Autocomplete
	const [utilisateurOkta, setUtilisateurOkta] = useState<SuiveurModel[]>([])
	// Utilisateur selectionné, permet de savoir s'il faut afficher les champs pré-rempli
	const [utilisateurChoisi, setUtilisateurChoisi] = useState<boolean>(false)
	// Etat d'ouverture de l'autocomplete de selection de suiveur
	const [autocompleteOuvert, setAutocompleteOuvert] = useState<boolean>(false)

	//Recupere les utilisateurs okta depuis le back
	const recuperationUtilisateur = (boutonRecherche?: boolean) => {
		// On lance la recherche à partir de 3 caractéres dans le champs de recherche
		if (getValues(champsSuiveurEnum.recherche).length >= 3 || boutonRecherche) {
			listeUtilisateurokta(getValues(champsSuiveurEnum.recherche))
				.then((response) => {
					setUtilisateurOkta(response.data)
					setAutocompleteOuvert(true)
				})
		} else {
			//Sinon on vide la liste de choix et on cache les champs vide
			setUtilisateurOkta([])
			setUtilisateurChoisi(false)
			setAutocompleteOuvert(false)
			setValue(champsSuiveurEnum.nom, '')
			setValue(champsSuiveurEnum.prenom, '')
			setValue(champsSuiveurEnum.courriel, '')
		}
	}

	const onSubmit: SubmitHandler<SuiveurModel> = (values: SuiveurModel) => {
		setFormValues({
			...formValues,
			subscribers: [...formValues.subscribers,
				{
					[champsSuiveurEnum.nom]: values[champsSuiveurEnum.nom],
					[champsSuiveurEnum.prenom]: values[champsSuiveurEnum.prenom],
					[champsSuiveurEnum.courriel]: values[champsSuiveurEnum.courriel]
				}
			]
		})
		reset(INITIAL_VALEURS_FORMULAIRE)
		setUtilisateurChoisi(false)
		setAutocompleteOuvert(false)
	}
	return (
		<Dialog
			onCloseDialog={() => {
				reset(INITIAL_VALEURS_FORMULAIRE)
				setUtilisateurChoisi(false)
				setAutocompleteOuvert(false)
				onCloseDialog()
			}}
			open={open}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box justifyContent="center" sx={{ width: 785, height: 550, ml: '40px', mr: '40px' }}>
				<Grid container item xs={12} justifyContent="space-evenly" sx={{ height: 122, mt: '20px', mb: '20px' }}>
					<Grid container sx={{
						height: 65,
						mt: '40px',
						mb: '20px',
						position: 'relative',
						'&::after': {
							top: '40px',
							width: '100%',
							height: '1px',
							content: '\' \'',
							display: 'inline-block',
							background: '#C4C4C4',
							position: 'absolute',
							right: 0
						}
					}}>
						<Box sx={{
							width: 681,
							height: 25,
							lineHeight: '25px',
							color: '#122941',
							flex: 'none',
							order: 0,
							flexGrow: 1,
							fontFamily: 'BlackerDisplay', fontSize: 22, fontWeight: 900
						}}>
							Ajouter un suiveur
						</Box>
						<Box sx={{
							height: 25,
							flex: 'none',
							order: 1,
							cursor: 'pointer',
							flexGrow: 1
						}} onClick={onCloseDialog}>
							<SvgClose color={gecinaBlueMain} />
						</Box>
					</Grid>
					<FormProvider {...methods}>
						<form
							onSubmit={handleSubmit(onSubmit)}
							style={{ width: '100%' }}
						>
							<Grid container spacing={2} sx={{ mb: '20px' }}>
								<Grid item xs>
									<Autocomplete
										id={champsSuiveurEnum.recherche}
										freeSolo
										open={autocompleteOuvert}
										sx={{ position: 'relative' }}
										// @ts-ignore
										onChange={(e, obj: SuiveurModel) => {
											setValue(champsSuiveurEnum.recherche, `${obj[champsSuiveurEnum.nom]} ${obj[champsSuiveurEnum.prenom]}` ?? '')
											setValue(champsSuiveurEnum.nom, obj[champsSuiveurEnum.nom] ?? '')
											setValue(champsSuiveurEnum.prenom, obj[champsSuiveurEnum.prenom] ?? '')
											setValue(champsSuiveurEnum.courriel, obj[champsSuiveurEnum.courriel] ?? '')
											setUtilisateurChoisi(true)
											setAutocompleteOuvert(false)
										}}
										openOnFocus
										options={utilisateurOkta}
										// @ts-ignore
										getOptionLabel={(option) => ('nom' in option && 'prenom' in option && `${option[champsSuiveurEnum.nom]} ${option[champsSuiveurEnum.prenom]}`) || ''}
										renderInput={(params) =>
											<div ref={params.InputProps.ref}>
												<TextField
													register={register(champsSuiveurEnum.recherche)}
													{...params}
													inputProps={{
														...params.inputProps,
														onKeyUp: () => recuperationUtilisateur(),
														value: watch(champsSignataireEnum.recherche),
														onChange: () => {}
													}}
													placeholder="Rechercher par nom ou prénom"
													sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain}` }}
												/>
												<SvgRecherche
													color={gecinaBlueMain}
													style={{
														cursor: 'pointer',
														top: '20px',
														left: '300px',
														position: 'absolute'
													}}
													onClick={() => recuperationUtilisateur(true)}
												/>
											</div>
										}
									/>
								</Grid>
							</Grid>
							{utilisateurChoisi &&
								<>
									<Grid container spacing={2} sx={{ mb: '20px' }}>
										<Grid item xs>
											<TextField
												sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain}`, opacity: '0.65' }}
												register={register(champsSuiveurEnum.nom, {
													required: 'Champ requis',
													maxLength: {
														value: 50,
														message: 'Le champs ne peut pas dépasser les 50 caractères'
													}
												})}
												errors={errors}
												id={champsSuiveurEnum.nom}
												label="Nom*"
												inputProps={{
													disabled: true
												}}
												placeholder="Saisir le nom du suiveur"
											/>
										</Grid>
										<Grid item xs>
											<TextField
												sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain} !important`, opacity: '0.65' }}
												register={register(champsSuiveurEnum.prenom, {
													required: 'Champ requis',
													maxLength: {
														value: 50,
														message: 'Le champs ne peut pas dépasser les 50 caractères'
													}
												})}
												errors={errors}
												disabled
												id={champsSuiveurEnum.prenom}
												label="Prénom*"
												inputProps={{
													disabled: true
												}}
												placeholder="Saisir le prénom du suiveur"
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid container item xs>
											<TextField
												sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain} !important`, opacity: '0.65' }}
												register={register(champsSuiveurEnum.courriel, {
													required: 'Champ requis',
													pattern: {
														value: /^(.+)@(.+)$/,
														message: 'Le courriel ne respecte pas le format'
													}
												})}
												errors={errors}
												disabled
												id={champsSuiveurEnum.courriel}
												label="Courriel*"
												inputProps={{
													disabled: true
												}}
												placeholder="Saisir le courriel du suiveur"
											/>
										</Grid>
									</Grid>
								</>
							}
							<Grid container item xs justifyContent="flex-end" sx={{ mt: '10px', ml: 0 }} spacing={2}>
								<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
									<Button
										sx={{
											height: '51px',
											p: 0,
											width: '195px',
											fontWeight: 600,
											lineHeight: '18.29px'
										}}
										bgcolor={gecinaBlueMain}
										color={gecinaBrownMain}
										border={false}
										disabled={!utilisateurChoisi}
										type={buttonTypesEnum.submit}
									>
										Ajouter
									</Button>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</Grid>
			</Box>
		</Dialog>
	)
}

export default PopinAjoutSuiveur