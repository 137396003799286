export interface FetchTokenModel {
	isAdmin: boolean,
	token: string,
	name: string
}

export const INITIAL_TOKEN: FetchTokenModel = {
	isAdmin: false,
	token: '',
	name: ''
}
