import { filtresListeProceduresFieldsEnum, filtresListeProceduresPostEnum, filtresListeProceduresResponseEnum, statutListeProceduresEnum } from '../enums/listeProceduresEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { fileTypeEnum } from '../../../enum/procedure/fileEnum'
import { typeSignataire } from '../../creationProcedure/types/ajoutSignatairePopinModel'

export interface FiltresListeProceduresFormModel {
	[filtresListeProceduresFieldsEnum.nomProcedure]: string,
	[filtresListeProceduresFieldsEnum.creeLe]: Date | string | null,
	[filtresListeProceduresFieldsEnum.avantLe]: Date | string | null,
	[filtresListeProceduresFieldsEnum.statut]: string
}

export const INITIAL_VALEURS_FORMULAIRE = {
	[filtresListeProceduresFieldsEnum.creeLe]: null,
	[filtresListeProceduresFieldsEnum.avantLe]: null,
	[filtresListeProceduresFieldsEnum.statut]: getKeyFromEnumValue<statutListeProceduresEnum>(statutListeProceduresEnum, statutListeProceduresEnum.all),
	[filtresListeProceduresFieldsEnum.nomProcedure]: ''
}

export interface FiltresListeProceduresPostModel {
	[filtresListeProceduresPostEnum.seeAll]?: boolean,
	[filtresListeProceduresPostEnum.name]?: string,
	[filtresListeProceduresPostEnum.status]?: string,
	[filtresListeProceduresPostEnum.before]?: string,
	[filtresListeProceduresPostEnum.after]?: string,
	[filtresListeProceduresPostEnum.page]?: number
}

export interface SignatairesListeProceduresModel {
	[filtresListeProceduresResponseEnum.proof]: string,
	[filtresListeProceduresResponseEnum.firstName]: string,
	[filtresListeProceduresResponseEnum.lastName]: string,
	[filtresListeProceduresResponseEnum.mobilePhone]: string,
	[filtresListeProceduresResponseEnum.email]: string,
	[filtresListeProceduresResponseEnum.status]: string,
	[filtresListeProceduresResponseEnum.type]: typeSignataire
}

export interface SuiveursListeProceduresModel {
	[filtresListeProceduresResponseEnum.email]: string
}

export interface DocumentsListeProceduresModel {
	[filtresListeProceduresResponseEnum.id]: string,
	[filtresListeProceduresResponseEnum.name]: string,
	[filtresListeProceduresResponseEnum.type]: fileTypeEnum.attachment | fileTypeEnum.signable,
}

export interface ListeProceduresResponseModel {
	[filtresListeProceduresResponseEnum.name]: string,
	[filtresListeProceduresResponseEnum.date_creation]: string,
	[filtresListeProceduresResponseEnum.date_expiration]: string,
	[filtresListeProceduresResponseEnum.status]: statutListeProceduresEnum,
	[filtresListeProceduresResponseEnum.signers]: SignatairesListeProceduresModel[],
	[filtresListeProceduresResponseEnum.subscribers]: SuiveursListeProceduresModel[],
	[filtresListeProceduresResponseEnum.files]: DocumentsListeProceduresModel[]
}

export interface PageListeProceduresModel {
	[filtresListeProceduresResponseEnum.current]: number,
	[filtresListeProceduresResponseEnum.last]: number
}

export interface FiltresListeProceduresResponseModel {
	[filtresListeProceduresResponseEnum.list]: ListeProceduresResponseModel[],
	[filtresListeProceduresResponseEnum.total]: number,
	[filtresListeProceduresResponseEnum.page]: PageListeProceduresModel
}

export const INITIAL_REPONSE_LISTE_PROCEDURE: FiltresListeProceduresResponseModel = {
	[filtresListeProceduresResponseEnum.list]: [],
	[filtresListeProceduresResponseEnum.total]: 0,
	[filtresListeProceduresResponseEnum.page]: {
		[filtresListeProceduresResponseEnum.current]: 0,
		[filtresListeProceduresResponseEnum.last]: 0
	}
}
