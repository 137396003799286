export const formatDate = (date: Date) => {
	return date && date.toString() !== 'Invalid Date' ? new Intl.DateTimeFormat('fr-FR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	}).format(new Date(date)).replace(/\//g, '/') : ''
}

export const formatDateLong = (date: Date, locale: string) => {
	return date && date.toString() !== 'Invalid Date' ? new Intl.DateTimeFormat(locale, {
		day: '2-digit',
		month: 'long',
		year: 'numeric'
	}).format(new Date(date)) : ''
}
