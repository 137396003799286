import { Autocomplete, Grid, MenuItem } from '@mui/material'
import TextField from '../../../../../components/fields/FormTextField'
import { gecinaBlueMain, gecinaBrownMain } from '../../../../../theme'
import { champsSignataireEnum } from '../enums/champsSignataireEnum'
import Button from '../../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../../components/button/enums/buttonTypesEnum'
import { INITIAL_VALEURS_FORMULAIRE } from '../types/ajoutSignataireModel'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import React, { useContext, useState } from 'react'
import { SignataireModel, SignataireRechercheModel } from '../../../types/CreationProcedureModel'
import { CreationProcedureContext } from '../../../CreationProcedure'
import { listeUtilisateurokta } from '../../../api/creationProcadureApi'
import SvgRecherche from '../../../../../icons/SvgRecherche'
import { typeSignataire } from '../../../types/ajoutSignatairePopinModel'
import FormSelectField from '../../../../../components/fields/FormSelectField'
import { capitalizeFirstLetter } from '../../../../../utils/utils'

interface AcceptingProps {
	onCloseDialog: () => void
}

type FormSignataireInterneProps = AcceptingProps

const FormSignataireInterne: React.FC<FormSignataireInterneProps> = ({ onCloseDialog }) => {
	const methods = useForm<SignataireRechercheModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset, formState: { errors }, getValues, setValue, watch, clearErrors } = methods
	const { setFormValues, formValues } = useContext(CreationProcedureContext)

	const onSubmit: SubmitHandler<SignataireModel> = (values: SignataireModel) => {
		setFormValues({
			...formValues,
			signers: [...formValues.signers,
				{
					[champsSignataireEnum.nom]: values[champsSignataireEnum.nom],
					[champsSignataireEnum.prenom]: values[champsSignataireEnum.prenom],
					[champsSignataireEnum.telephone]: values[champsSignataireEnum.telephone],
					[champsSignataireEnum.type]: values[champsSignataireEnum.type],
					[champsSignataireEnum.courriel]: values[champsSignataireEnum.courriel]
				}
			]
		})
		reset(INITIAL_VALEURS_FORMULAIRE)
		setUtilisateurChoisi(false)
	}

	// Liste des utilisateurs affiché dans l'Autocomplete
	const [utilisateurOkta, setUtilisateurOkta] = useState<SignataireModel[]>([])
	// Utilisateur selectionné, permet de savoir s'il faut afficher les champs pré-rempli
	const [utilisateurChoisi, setUtilisateurChoisi] = useState<boolean>(false)
	// Etat d'ouverture de l'autocomplete de selection de signataire
	const [autocompleteOuvert, setAutocompleteOuvert] = useState<boolean>(false)

	//Recupere les utilisateurs okta depuis le back
	const recuperationUtilisateur = (boutonRecherche?: boolean) => {
		// On lance la recherche à partir de 3 caractéres dans le champs de recherche
		if (getValues(champsSignataireEnum.recherche).length >= 3 || boutonRecherche) {
			listeUtilisateurokta(getValues(champsSignataireEnum.recherche))
				.then((response) => {
					setUtilisateurOkta(response.data)
					setAutocompleteOuvert(true)
				})
		} else {
			//Sinon on vide la liste de choix et on cache les champs vide
			setUtilisateurOkta([])
			setUtilisateurChoisi(false)
			setAutocompleteOuvert(false)
			setValue(champsSignataireEnum.nom, '')
			setValue(champsSignataireEnum.prenom, '')
			setValue(champsSignataireEnum.telephone, '')
			setValue(champsSignataireEnum.courriel, '')
		}
	}

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{ width: '100%' }}
			>
				<Grid container spacing={2} sx={{ mb: '20px' }}>
					<Grid item xs>
						<Autocomplete
							id={champsSignataireEnum.recherche}
							freeSolo
							open={autocompleteOuvert}
							sx={{ position: 'relative' }}
							// @ts-ignore
							onChange={(e, obj: SignataireModel) => {
								clearErrors()
								setValue(champsSignataireEnum.recherche, `${obj[champsSignataireEnum.nom]} ${obj[champsSignataireEnum.prenom]}` ?? '')
								setValue(champsSignataireEnum.nom, obj[champsSignataireEnum.nom] ?? '')
								setValue(champsSignataireEnum.prenom, obj[champsSignataireEnum.prenom] ?? '')
								setValue(champsSignataireEnum.telephone, obj[champsSignataireEnum.telephone] ?? '')
								setValue(champsSignataireEnum.courriel, obj[champsSignataireEnum.courriel] ?? '')
								setUtilisateurChoisi(true)
								setAutocompleteOuvert(false)
							}}
							openOnFocus
							options={utilisateurOkta}
							// @ts-ignore
							getOptionLabel={(option) => ('nom' in option && 'prenom' in option && `${option[champsSignataireEnum.nom]} ${option[champsSignataireEnum.prenom]}`) || ''}
							renderInput={(params) =>
								<div ref={params.InputProps.ref}>
									<TextField
										register={register(champsSignataireEnum.recherche)}
										{...params}
										inputProps={{
											...params.inputProps,
											onKeyUp: () => recuperationUtilisateur(),
											value: watch(champsSignataireEnum.recherche),
											onChange: () => {
											}
										}}
										placeholder="Rechercher par nom ou prénom"
										sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain}` }}
									/>
									<SvgRecherche
										color={gecinaBlueMain}
										style={{
											cursor: 'pointer',
											top: '20px',
											left: '300px',
											position: 'absolute'
										}}
										onClick={() => recuperationUtilisateur(true)}
									/>
								</div>
							}
						/>
					</Grid>
				</Grid>
				{utilisateurChoisi &&
					<>
						<Grid container spacing={2} sx={{ mb: '20px' }}>
							<Grid item xs>
								<TextField
									sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain}`, opacity: '0.65' }}
									register={register(champsSignataireEnum.nom, {
										required: 'Champ requis',
										maxLength: {
											value: 50,
											message: 'Le champs ne peut pas dépasser les 50 caractères'
										}
									})}
									errors={errors}
									id={champsSignataireEnum.nom}
									label="Nom*"
									inputProps={{
										disabled: true
									}}
									placeholder="Saisir le nom du signataire"
								/>
							</Grid>
							<Grid item xs>
								<TextField
									sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain} !important`, opacity: '0.65' }}
									register={register(champsSignataireEnum.prenom, {
										required: 'Champ requis',
										maxLength: {
											value: 50,
											message: 'Le champs ne peut pas dépasser les 50 caractères'
										}
									})}
									errors={errors}
									disabled
									id={champsSignataireEnum.prenom}
									label="Prénom*"
									inputProps={{
										disabled: true
									}}
									placeholder="Saisir le prénom du signataire"
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} sx={{ mb: '20px' }}>
							<Grid item xs>
								<TextField
									sx={{
										height: 51,
										width: 345,
										border: `1px solid ${gecinaBlueMain} !important`,
										opacity: '0.65'
									}}
									register={register(champsSignataireEnum.telephone, {
										required: 'Champ requis',
										maxLength: {
											value: 18,
											message: 'Le champs ne peut pas dépasser les 18 caractères'
										}
									})}
									errors={errors}
									disabled
									id={champsSignataireEnum.telephone}
									label="Téléphone mobile*"
									inputProps={{
										disabled: true
									}}
									placeholder="Saisir le téléphone du signataire"
								/>
							</Grid>
							<Grid item xs>
								<TextField
									sx={{
										height: 51,
										width: 345,
										border: `1px solid ${gecinaBlueMain} !important`,
										opacity: '0.65'
									}}
									register={register(champsSignataireEnum.courriel, {
										required: 'Champ requis',
										pattern: {
											value: /^(.+)@(.+)$/,
											message: 'Le courriel ne respecte pas le format'
										}
									})}
									errors={errors}
									disabled
									id={champsSignataireEnum.courriel}
									label="Courriel*"
									inputProps={{
										disabled: true
									}}
									placeholder="Saisir le courriel du signataire"
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormSelectField
									register={register(champsSignataireEnum.type)}
									id={champsSignataireEnum.type}
									label="Type"
									sx={{
										height: 51,
										width: 345,
										border: `1px solid ${gecinaBlueMain} !important`,
										opacity: '0.65'
									}}
								>
									{
										Object.keys(typeSignataire).map(
											(type) => (
												<MenuItem value={type} key={type}>
													{capitalizeFirstLetter(typeSignataire[type as keyof typeof typeSignataire])}
												</MenuItem>
											)
										)
									}
								</FormSelectField>
							</Grid>
						</Grid>
					</>
				}
				<Grid container item xs justifyContent="flex-end" sx={{ mt: '10px', ml: 0 }} spacing={2}>
					<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={gecinaBlueMain}
							color={gecinaBrownMain}
							border={false}
							disabled={!utilisateurChoisi}
							type={buttonTypesEnum.submit}
						>
							Ajouter
						</Button>
					</Grid>
				</Grid>
			</form>
		</FormProvider>)
}

export default FormSignataireInterne