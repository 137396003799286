export const downloadBlob = (data: any, fileName: string) => {
	const url = window.URL.createObjectURL(new Blob([data]))
	const downloadLink = document.createElement('a')
	downloadLink.href = url
	downloadLink.download = convertToValidFilename(fileName)
	downloadLink.click()
}

/**
 * Convert to a valid filename
 * Cannot contain: < > : " / \ | ? *
 * Replace with "_"
 * @param filename
 */
export const convertToValidFilename = (filename: string) => (filename.replace(/[\/|\\:*?"<>]/g, '_'))