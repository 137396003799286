import { IconsModel } from './types/iconsModel'

const SvgRecherche = (
	{
		color,
		width = 25,
		height = 24,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
		<path
			d="M22.241 24L14.827 16.586C13.1945 17.8053 11.2126 18.4662 9.175 18.471H9.173L9.065 18.472C6.66081 18.472 4.35509 17.5169 2.65508 15.8169C0.95506 14.1169 0 11.8112 0 9.40698L0.001 9.29298V9.29898C0.001 6.83299 0.980608 4.46801 2.72432 2.7243C4.46803 0.980585 6.83302 0.000976563 9.299 0.000976562C11.765 0.000976563 14.13 0.980585 15.8737 2.7243C17.6174 4.46801 18.597 6.83299 18.597 9.29898C18.5712 11.3453 17.9054 13.3322 16.693 14.981L16.712 14.954L24.126 22.368L22.241 24ZM9.299 2.51298C7.51037 2.41002 5.75405 3.02153 4.41612 4.21306C3.07819 5.4046 2.26815 7.07865 2.16407 8.86722C2.05999 10.6558 2.67039 12.4125 3.86108 13.7512C5.05177 15.0899 6.72531 15.9009 8.51381 16.0061C10.3023 16.1114 12.0594 15.5021 13.3988 14.3122C14.7383 13.1224 15.5504 11.4493 15.6567 9.6609C15.7631 7.87247 15.1549 6.11499 13.9659 4.77481C12.7769 3.43463 11.1044 2.62144 9.316 2.51398H9.299H9.3L9.299 2.51298Z"
			fill={color} />
	</svg>
)

export default SvgRecherche