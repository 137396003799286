import React, { useState } from 'react'
import { ChampDansFormulaire } from './types/typePourChampDansFormulaireModel'
import { Input, Select, SelectProps } from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'
import { fontFamilyMonserrat } from '../../theme'
import ErrorComponent from './ErrorComponent'
import { CUSTOM_TEXT_FIELD_STYLE } from './CustomTextField'

type FormSelectFieldProps = ChampDansFormulaire & SelectProps

const FormSelectField: React.FC<FormSelectFieldProps> = (
	{
		id,
		label,
		register,
		defaultValue = '',
		sx,
		children
	}
) => {
	const { formState: { errors } } = useForm()
	const { setValue, watch } = useFormContext()

	const CUSTOM_STYLE = () => ({
		...sx
	})

	return (
		<>
			{
				label && (
					<span
						style={{
							width: '100%',
							paddingRight: 15,
							paddingTop: 7,
							fontSize: 14,
							marginBottom: 5,
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left',
							fontFamily: fontFamilyMonserrat,
							fontWeight: 500
						}}
					>
						{label}
					</span>
				)
			}

			<Select
				name={register.name}
				value={watch(register.name)}
				onChange={(value) => setValue(register.name, value.target.value)}
				id={id}
				input={
					<Input
						color="primary"
						sx={[CUSTOM_TEXT_FIELD_STYLE(register, errors), CUSTOM_STYLE()]}
						id={`select-text-field-${register.name}`}
						disableUnderline
						onBlur={() => {
						}}
					/>
				}
			>
				{children}
			</Select>

			{
				register.name in errors && (
					<ErrorComponent>
						{errors[register.name].message}
					</ErrorComponent>
				)
			}
		</>
	)
}

export default FormSelectField
