import React, { useContext } from 'react'
import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { HeadRow } from '../../../components/table/types/tableModel'
import { enteteTableauListeSuiveursEnum } from '../enums/creationProcedureEnum'
import { SuiveurModel } from '../types/CreationProcedureModel'
import { mapperSuiveursPourListe } from '../utils/creationProcedureUtils'
import { gecinaBrownMain } from '../../../theme'
import SvgTrash from '../../../icons/SvgTrash'
import { CreationProcedureContext } from '../CreationProcedure'



const ListeSuiveurs = () => {
	const { setFormValues, formValues } = useContext(CreationProcedureContext)
	
	const entetesTableau: HeadRow[] =
		[
			{
				label: enteteTableauListeSuiveursEnum.nom,
				id: getKeyFromEnumValue<enteteTableauListeSuiveursEnum>(enteteTableauListeSuiveursEnum, enteteTableauListeSuiveursEnum.nom)
			},
			{
				label: enteteTableauListeSuiveursEnum.prenom,
				id: getKeyFromEnumValue<enteteTableauListeSuiveursEnum>(enteteTableauListeSuiveursEnum, enteteTableauListeSuiveursEnum.prenom)
			},
			{
				label: enteteTableauListeSuiveursEnum.courriel,
				id: getKeyFromEnumValue<enteteTableauListeSuiveursEnum>(enteteTableauListeSuiveursEnum, enteteTableauListeSuiveursEnum.courriel)
			},
			{
				label: enteteTableauListeSuiveursEnum.supprimer,
				id: getKeyFromEnumValue<enteteTableauListeSuiveursEnum>(enteteTableauListeSuiveursEnum, enteteTableauListeSuiveursEnum.supprimer),
				render: (index: number) => (<SvgTrash style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => setFormValues({ ...formValues, subscribers: formValues.subscribers.filter((value, indexFilter) => index !== indexFilter) })} />)
			}
		]

	if (formValues.subscribers.length === 0) {
		return <></>
	}

	return (
		<Box sx={{ width: '100%' }}>
			<CustomTable
				id="ListeSuiveurss"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<SuiveurModel>(mapperSuiveursPourListe(formValues.subscribers), entetesTableau)}
				hasPagination={false}
			/>
		</Box>
	)
}

export default ListeSuiveurs
