import React, { useMemo } from 'react'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { SuiveursListeProceduresModel } from '../../listeProcedures/types/filtresListeProceduresModel'
import CustomTable from '../../../components/table/Table'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { enteteTableauDetailSuiveursEnum } from '../enums/detailProcedureEnum'

interface AcceptingProps {
	suiveurs: SuiveursListeProceduresModel[]
}

type DetailSuiveursProps = AcceptingProps

const DetailSuiveurs: React.FC<DetailSuiveursProps> = (
	{
		suiveurs
	}
) => {
	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauDetailSuiveursEnum.email,
					id: getKeyFromEnumValue<enteteTableauDetailSuiveursEnum>(enteteTableauDetailSuiveursEnum, enteteTableauDetailSuiveursEnum.email)
				}
			]
		), []
	)

	return (
		<CustomTable
			id="detailSuiveurs"
			titre="Suiveurs"
			headRows={entetesTableau}
			rows={remplissageLigneSelonEnteteTableau<SuiveursListeProceduresModel>(suiveurs, entetesTableau)}
			hasPagination={false}
		/>
	)
}

export default DetailSuiveurs
