import React from 'react'
import { Box } from '@mui/material'
import { gecinaBlueMain, gecinaBrownMain } from '../../theme'
import { useNavigate } from 'react-router-dom'
import liens from '../../enum/liens'
import { ListeProceduresResponseModel } from '../../modules/listeProcedures/types/filtresListeProceduresModel'
import { filtresListeProceduresResponseEnum } from '../../modules/listeProcedures/enums/listeProceduresEnum'

interface AcceptingProps {
	procedure?: ListeProceduresResponseModel
}

type VueProcedureArianeProps = AcceptingProps

const VueProcedureAriane: React.FC<VueProcedureArianeProps> = (
	{
		procedure
	}) => {
	const navigate = useNavigate()

	const formatDateToLocalString = (date?: string) => !!date ? new Date(date).toLocaleDateString() : '-'

	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
			<Box onClick={() => navigate(liens.listeProcedure, { state: { rafraichir: true } })}
			     sx={{
				     width: 'fit-content',
				     fontFamily: 'Montserrat',
				     fontSize: 16,
				     color: gecinaBrownMain,
				     display: 'flex',
				     flexDirection: 'row',
				     paddingTop: '21px',
				     '&:hover': { cursor: 'pointer' }
			     }}>
				<img src="/img/back_arrow.png" height={20} alt="Back arrow" />
				<Box sx={{ marginLeft: '10px' }}>Retour à la liste</Box>
			</Box>
			<Box sx={{ paddingTop: '22px', fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
				{procedure?.[filtresListeProceduresResponseEnum.name] || ''}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '40px' }}>
				<Box sx={{ fontFamily: 'Montserrat' }}>
					<Box sx={{ fontSize: 12 }}>Statut</Box>
					<Box sx={{ fontWeight: 600 }}>{procedure?.[filtresListeProceduresResponseEnum.status] || ''}</Box>
				</Box>
				<Box sx={{ fontFamily: 'Montserrat', marginLeft: '180px' }}>
					<Box sx={{ fontSize: 12 }}>Date de création</Box>
					<Box sx={{ fontWeight: 600 }}>{formatDateToLocalString(procedure?.[filtresListeProceduresResponseEnum.date_creation])}</Box>
				</Box>
				<Box sx={{ fontFamily: 'Montserrat', marginLeft: '180px' }}>
					<Box sx={{ fontSize: 12 }}>Date d'expiration</Box>
					<Box sx={{ fontWeight: 600 }}>{formatDateToLocalString(procedure?.[filtresListeProceduresResponseEnum.date_expiration])}</Box>
				</Box>
			</Box>
		</Box>
	)

}

export default VueProcedureAriane
