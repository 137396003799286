import React from 'react'
import VueProcedureAriane from '../../components/ariane/VueProcedureAriane'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListeProceduresResponseModel } from '../listeProcedures/types/filtresListeProceduresModel'
import liens from '../../enum/liens'
import { filtresListeProceduresResponseEnum } from '../listeProcedures/enums/listeProceduresEnum'
import { Grid } from '@mui/material'
import DetailSignataires from './components/DetailSignataires'
import DetailSuiveurs from './components/DetailSuiveurs'
import DetailDocuments from './components/DetailDocuments'

interface DetailProcedureState {
	procedure: ListeProceduresResponseModel
}

const DetailProcedure = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const procedure = location.state
		? (location.state as DetailProcedureState).procedure
		: null

	if (!procedure) {
		navigate(liens.listeProcedure, { state: { rafraichir: true } })

		return <></>
	}

	return (
		<>
			<VueProcedureAriane procedure={procedure} />

			<Grid container sx={{ paddingX: '172px' }}>
				{/* Tableau récapitulant les signataires, masqué si pas de signataire */}
				{
					procedure[filtresListeProceduresResponseEnum.signers].length > 0 && (
						<DetailSignataires signataires={procedure[filtresListeProceduresResponseEnum.signers]} />
					)
				}

				{/* Tableau récapitulant les suiveurs, masqué si pas de suiveur */}
				{
					procedure[filtresListeProceduresResponseEnum.subscribers].length > 0 && (
						<DetailSuiveurs suiveurs={procedure[filtresListeProceduresResponseEnum.subscribers]} />
					)
				}

				{/* Tableau récapitulant les documents, masqué si pas de document */}
				{
					procedure[filtresListeProceduresResponseEnum.files].length > 0 && (
						<DetailDocuments documents={procedure[filtresListeProceduresResponseEnum.files]} />
					)
				}
			</Grid>
		</>
	)
}

export default DetailProcedure
