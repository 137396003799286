import { Box } from '@mui/material'
import { gecinaBlueMain, WHITE } from '../../../theme'
import React from 'react'

const EnteteCreationProcedure = () => {
	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: '111px', position: 'sticky', top: '80px', paddingX: '172px', zIndex: 1 }}>
			<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: '28px', fontWeight: '900', color: WHITE, display: 'flex', flexDirection: 'row', paddingTop: '40px' }}>
				Nouvelle procédure
			</Box>
		</Box>
	)
}

export default EnteteCreationProcedure