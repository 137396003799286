import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import Dialog from '../../../components/dialog/Dialog'
import { Box, Grid } from '@mui/material'
import React from 'react'
import SvgClose from '../../../icons/SvgClose'
import { gecinaBlueMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import HttpStatusCode from '../../../enum/httpStatusCode'

interface AcceptingProps {
	response: { message: string, statut: number }
}

type PopinRetourCreationProps = AcceptingProps & DialogCommonProps

const PopinRetourCreation: React.FC<PopinRetourCreationProps> = ({ onCloseDialog, open, response }) => {

	const titrePopin = () => {
		switch (response.statut) {
			case HttpStatusCode.OK:
				return 'Succès de la création'
			case HttpStatusCode.SERVICE_UNAVAILABLE:
				return 'Yousign ne répond pas'
			case HttpStatusCode.INTERNAL_SERVER_ERROR:
				return 'Echec de la création'
		}
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open && response.statut !== 0}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box justifyContent="center" sx={{ width: 785, height: 'fit-content', ml: '40px', mr: '40px' }}>
				<Grid container sx={{
					height: 65, mt: '40px', mb: '20px',
					'&::after': {
						top: '110px',
						width: '90%',
						height: '1px',
						content: '\' \'',
						display: 'inline-block',
						background: '#C4C4C4',
						position: 'absolute',
						right: '50px'
					}
				}}>
					<Box sx={{
						width: 681,
						height: 25,
						lineHeight: '25px',
						color: '#122941',
						flex: 'none',
						order: 0,
						flexGrow: 1,
						fontFamily: 'BlackerDisplay', fontSize: 22, fontWeight: 900
					}}>
						{titrePopin()}
					</Box>
					<Box sx={{
						height: 25,
						flex: 'none',
						order: 1,
						flexGrow: 1,
						cursor: 'pointer'
					}} onClick={onCloseDialog}>
						<SvgClose color={gecinaBlueMain} />
					</Box>
				</Grid>
				<Grid container item xs={12} justifyContent="space-evenly" sx={{ height: 'fit-content', mt: '20px', mb: '20px' }}>
					<Box sx={{
						color: '#122941',
						fontFamily: 'Montserrat',
						fontSize: 18,
						height: 'fit-content'
					}}>
						<>
							{
								response.statut === HttpStatusCode.INTERNAL_SERVER_ERROR &&
								<>
									La procédure n'a pas pu être créée, erreur rencontrée :
									<br />
									<br />
									{
										response.message !== '' &&
										<ul>
											<li>{response.message}</li>
										</ul>
									}
								</>
							}
							{
								response.statut === HttpStatusCode.SERVICE_UNAVAILABLE &&
								<>
									Le service Yousign ne répond pas ou peut être en cours de maintenance. Il est recommandé de contacter le service Yousign.
								</>
							}
							{
								response.statut === HttpStatusCode.OK &&
								<>
									Votre procédure a été créée avec succés. Un mail de confirmation a été envoyé aux membres de la procédure
								</>
							}
						</>
					</Box>
				</Grid>
				<Grid container item xs justifyContent="flex-end" sx={{ mt: '10px', ml: 0 }} spacing={2}>
					<Grid item sx={{ maxWidth: 'max-content', minWidth: 'fit-content' }}>
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={WHITE}
							color={gecinaBlueMain}
							border={true}
							disabled={false}
							type={buttonTypesEnum.button}
							onClick={onCloseDialog}
						>
							Fermer
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	)
}

export default PopinRetourCreation