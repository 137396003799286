import React, { useCallback, useContext, useMemo } from 'react'
import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { enteteTableauAffichageListeEnum, filtresListeProceduresPostEnum, filtresListeProceduresResponseEnum } from '../enums/listeProceduresEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { ListeProceduresContext } from '../context/ListeProceduresContext'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { ListeProceduresResponseModel } from '../types/filtresListeProceduresModel'
import { HeadRow } from '../../../components/table/types/tableModel'
import { tableTypeEnum } from '../../../components/table/enums/tableTypeEnum'
import { getListeProcedure } from '../api/listeProcedureAPI'
import { useNavigate } from 'react-router-dom'
import liens from '../../../enum/liens'

const AffichageListe = () => {
	const { listeProcedures, donneesFormulaire, setListeProcedures, setDonneesFormulaire } = useContext(ListeProceduresContext)
	const navigate = useNavigate()

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauAffichageListeEnum.name,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.name)
				},
				{
					label: enteteTableauAffichageListeEnum.date_creation,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.date_creation),
					type: tableTypeEnum.date
				},
				{
					label: enteteTableauAffichageListeEnum.date_expiration,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.date_expiration),
					type: tableTypeEnum.date
				},
				{
					label: enteteTableauAffichageListeEnum.status,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.status)
				}
			]
		), []
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...donneesFormulaire,
				[filtresListeProceduresPostEnum.page]: nouvellePage,
				[filtresListeProceduresPostEnum.seeAll]: donneesFormulaire[filtresListeProceduresPostEnum.seeAll] ?? false
			}

			getListeProcedure(data)
				.then((response) => {
					setListeProcedures(response)
					setDonneesFormulaire(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	const auCliqueSurLigne = useCallback(
		(procedureSelectionnee: ListeProceduresResponseModel) => {
			const procedure = listeProcedures[filtresListeProceduresResponseEnum.list].filter((listeProcedure) => listeProcedure[filtresListeProceduresResponseEnum.name] === procedureSelectionnee[filtresListeProceduresResponseEnum.name])[0]

			navigate(liens.detailProcedure, { state: { procedure } })
		}, [listeProcedures]
	)

	if (listeProcedures[filtresListeProceduresResponseEnum.list].length === 0) {
		return <></>
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeProcedures"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ListeProceduresResponseModel>(listeProcedures[filtresListeProceduresResponseEnum.list], entetesTableau)}
				total={listeProcedures[filtresListeProceduresResponseEnum.total]}
				auChangementDePage={auChangementDePage}
				page={(listeProcedures[filtresListeProceduresPostEnum.page][filtresListeProceduresResponseEnum.current] ?? 1) - 1}
				auCliqueSurLigne={auCliqueSurLigne}
				hasSort={false}
				borderBottom
			/>
		</Box>
	)
}

export default AffichageListe
