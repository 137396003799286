import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgFlecheVersBas = (
	{
		color,
		width = 14,
		height = 20,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd"
		      d="M7.06239 0.492188C7.24369 0.492188 7.41757 0.564209 7.54576 0.692407C7.67396 0.820606 7.74598 0.994481 7.74598 1.17578V17.299L12.0472 12.9965C12.1755 12.8681 12.3496 12.796 12.5311 12.796C12.7127 12.796 12.8868 12.8681 13.0151 12.9965C13.1435 13.1248 13.2156 13.2989 13.2156 13.4805C13.2156 13.662 13.1435 13.8361 13.0151 13.9645L7.54637 19.4332C7.48287 19.4969 7.40744 19.5474 7.32439 19.5818C7.24134 19.6163 7.15231 19.634 7.06239 19.634C6.97247 19.634 6.88344 19.6163 6.80039 19.5818C6.71734 19.5474 6.6419 19.4969 6.5784 19.4332L1.10965 13.9645C0.981292 13.8361 0.90918 13.662 0.90918 13.4805C0.90918 13.2989 0.981292 13.1248 1.10965 12.9965C1.23801 12.8681 1.41211 12.796 1.59364 12.796C1.77517 12.796 1.94926 12.8681 2.07762 12.9965L6.3788 17.299V1.17578C6.3788 0.994481 6.45082 0.820606 6.57902 0.692407C6.70722 0.564209 6.88109 0.492188 7.06239 0.492188Z"
		      fill={color} />
	</svg>
)
export default SvgFlecheVersBas