export enum creationProceduresFieldsEnum {
	nom = 'name',
	date_expiration = 'expirationDate',
	uuid = 'uuid',
	signataires = 'signers',
	suiveurs = 'subscribers',
	documents_a_signer = 'signable_files',
	documents_en_consultation = 'attachment_files'
}

export enum enteteTableauListeSignataireEnum {
	nom = 'Nom',
	prenom = 'Prénom',
	telephone = 'Téléphone',
	courriel = 'Courriel',
	type = 'Type',
	ordre = 'Ordre',
	supprimer = ''
}

export enum enteteTableauListeSuiveursEnum {
	nom = 'Nom',
	prenom = 'Prénom',
	courriel = 'Courriel',
	supprimer = ''
}