import { FiltresListeProceduresFormModel, FiltresListeProceduresPostModel, INITIAL_VALEURS_FORMULAIRE } from '../types/filtresListeProceduresModel'
import { filtresListeProceduresFieldsEnum, filtresListeProceduresPostEnum } from '../enums/listeProceduresEnum'
import { formatDate } from '../../../utils/formatDate'

export const mapperListeProceduresVersBack = (values: FiltresListeProceduresFormModel, page: number): FiltresListeProceduresPostModel => (
	{
		[filtresListeProceduresPostEnum.name]: values[filtresListeProceduresFieldsEnum.nomProcedure].length > 0 ? values[filtresListeProceduresFieldsEnum.nomProcedure] : undefined,
		[filtresListeProceduresPostEnum.status]: values[filtresListeProceduresFieldsEnum.statut].length > 0 ? values[filtresListeProceduresFieldsEnum.statut] : undefined,
		[filtresListeProceduresPostEnum.after]: values[filtresListeProceduresFieldsEnum.creeLe] ? new Date(values[filtresListeProceduresFieldsEnum.creeLe]!).toISOString().split('T')[0] : undefined,
		[filtresListeProceduresPostEnum.before]: values[filtresListeProceduresFieldsEnum.avantLe] ? new Date(values[filtresListeProceduresFieldsEnum.avantLe]!).toISOString().split('T')[0] : undefined,
		[filtresListeProceduresPostEnum.page]: page + 1
	}
)

export const mapperBackVersListeProcedures = (values: FiltresListeProceduresPostModel): FiltresListeProceduresFormModel => (
	{
		[filtresListeProceduresFieldsEnum.nomProcedure]: values && values[filtresListeProceduresPostEnum.name] && values[filtresListeProceduresPostEnum.name]!.length > 0 ? (values[filtresListeProceduresPostEnum.name] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.nomProcedure],
		[filtresListeProceduresFieldsEnum.statut]: values && values[filtresListeProceduresPostEnum.status] && values[filtresListeProceduresPostEnum.status]!.length > 0 ? (values[filtresListeProceduresPostEnum.status] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.statut],
		[filtresListeProceduresFieldsEnum.creeLe]: values && values[filtresListeProceduresPostEnum.after] ? new Date(values[filtresListeProceduresPostEnum.after]!) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.creeLe],
		[filtresListeProceduresFieldsEnum.avantLe]: values && values[filtresListeProceduresPostEnum.before] ? formatDate(new Date(values[filtresListeProceduresPostEnum.before]!)) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.avantLe]
	}
)
