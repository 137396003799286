import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import SvgUpload from '../../icons/SvgUpload'
import { gecinaBlueMain, gecinaBrown10, gecinaBrownMain, WHITE } from '../../theme'
import { formatFilename } from '../../utils/formatFilename'
import Button from '../button/Button'
import { FileUploader } from 'react-drag-drop-files'
import { buttonTypesEnum } from '../button/enums/buttonTypesEnum'
import { deleteFile, sendFile } from '../../modules/creationProcedure/api/creationProcadureApi'
import Loader from '../loader/Loader'
import SvgTrash from '../../icons/SvgTrash'
import { fileTypeEnum } from '../../enum/procedure/fileEnum'
import ErrorComponent from './ErrorComponent'
import { Control } from 'react-hook-form'
import ErrorMessage from '../error/ErrorMessage'

const AcceptedExtensions: string[] = ['pdf']

interface AcceptingProps {
	name: string,
	id: string,
	label: string,
	fileType: fileTypeEnum.signable | fileTypeEnum.attachment,
	onChange: (list: string[]) => void,
	control: Control<any>
}

type CustomFormFileProps = AcceptingProps

const CustomFormFile: React.FC<CustomFormFileProps> = (
	{
		name,
		id,
		label,
		fileType,
		onChange,
		control
	}
) => {
	const [loadingAdd, setLoadingAdd] = useState<boolean>(false)
	const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
	const [deleteIndex, setDeleteIndex] = useState<number>(-1)
	const [documentList, setDocumentList] = useState<string[]>([])
	const [errorMessage, setErrorMessage] = useState<string>('')

	useEffect(() => {
		onChange(documentList)
	}, [documentList])

	const resetErrorMessage = () => setErrorMessage('')

	const isCorrectExtension = (name: string) => {
		const lastDot = name.lastIndexOf('.')
		const ext = name.substring(lastDot + 1)
		return AcceptedExtensions.includes(ext.toLowerCase())
	}

	const addFile = (file: File) => {
		if (isCorrectExtension(file.name)) {
			toBase64(file)
				.then((response: any) => {
					setLoadingAdd(true)
					sendFile({
						name: response.file,
						fileData: response.encodedFile,
						uuid: localStorage.getItem('uuid') ?? '',
						type: fileType
					}).then(() => setDocumentList((prevState => [...prevState, response.file])))
				})
				.finally(() => setLoadingAdd(false))
		} else {
			setErrorMessage('Certains fichiers n\'ont pas pu être chargés. Seuls les fichiers PDF sont autorisés.')
		}
	}

	const handleDragDrop = useCallback((files: FileList) => {
		resetErrorMessage()
		for (let i = 0; i < files.length; i++) {
			if (documentList.some((file) => file === formatFilename(files[i].name))) {
				setErrorMessage('Certains fichiers n\'ont pas pu être chargés, un fichier du même nom existe déjà.')
			} else {
				addFile(files[i])
			}
		}
	}, [documentList])

	const toBase64 = (file: File) => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(
			{
				file: formatFilename(file.name),
				encodedFile: reader.result
			}
		)
		reader.onerror = error => reject(error)
	})

	/*const onFileClick = (index: number) => {
		'fileName' in fields.value[0] && 'url' in fields.value[0] && downloadFile({
			file: getFilename(fields.value[index]),
			url: getData(fields.value[index])
		})
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.target = '_blank'
					a.click()
				}
			)
			.catch(
				() => {
					return dispatch(
						displaySnackbar(
							{
								id: INPUT_FILE,
								message: intl.formatMessage({
									id: 'dossier.errors.downloadError',
									defaultMessage: 'Vous ne pouvez pas télécharger ce document',
									description: 'error download file'
								}),
								open: true,
								hideIcon: true
							}
						)
					)
				}
			)
	}*/

	const onFileDelete = (index: number) => {
		resetErrorMessage()
		setLoadingDelete(true)

		deleteFile({
			name: documentList[index],
			uuid: localStorage.getItem('uuid') ?? '',
			type: fileType
		})
			.then(() => documentList.splice(index, 1))
			.finally(() => {
				setDeleteIndex(-1)
				setLoadingDelete(false)
			})
	}

	const addAttachment = useCallback(() => (
		<Grid item container alignItems="center" sx={{
			border: 'dashed #727E8E',
			backgroundColor: gecinaBrown10,
			position: 'relative',
			'& > label': {
				width: '100%'
			}
		}}>
			<FileUploader
				fileOrFiles={null}
				handleChange={handleDragDrop}
				multiple
			>
				<Button
					bgcolor={gecinaBrown10}
					color="colorGrey"
					type={buttonTypesEnum.button}
					sx={{
						justifyContent: 'flex-start',
						display: 'flex',
						width: '100%',
						height: '100%',
						flexWrap: 'nowrap',
						alignItems: 'center'
					}}
					border={true}
					disabled={false}
				>
					{
						loadingAdd ? (
							<Loader size={42} />
						) : (
							<SvgUpload color={gecinaBrownMain} />
						)
					}
					<span style={{ color: 'grey', marginLeft: '22px' }}>
						Glisser / Déposer le fichier ici, ou <span style={{ color: gecinaBlueMain, textDecoration: 'underline #122941' }}>Parcourir vos fichiers</span>
					</span>
				</Button>
			</FileUploader>
			<br />
		</Grid>
	), [loadingAdd, errorMessage, handleDragDrop])

	return (
		<Grid container>
			<Grid container item>
				<Grid container item alignItems="center" sx={{ paddingBottom: '20px' }}>
					<Grid item xs>
						<span style={{
							fontFamily: 'BlackerDisplay',
							fontSize: 22,
							fontWeight: 900,
							color: gecinaBlueMain
						}}>
							{`${label} (${documentList.length})`}
						</span>
					</Grid>
					<Grid container item>
						<ErrorMessage control={control} name={name} />
					</Grid>
				</Grid>
			</Grid>
			<Grid container sx={{
				width: '645px',
				background: WHITE
			}}>
				<>
					{addAttachment()}
				</>
				<Grid container sx={{
					paddingTop: '10px',
					backgroundColor: gecinaBrown10
				}}>
					<ErrorComponent>
						{errorMessage && errorMessage}
					</ErrorComponent>
				</Grid>
				{
					documentList.length ?
						documentList.map((field: any, index: number) =>
							(
								<Grid container key={`${id}-${field}`}
								      sx={{
									      flexDirection: 'row',
									      flexWrap: 'nowrap',
									      justifyContent: 'space-between',
									      alignItems: 'center',
									      backgroundColor: gecinaBrown10,
									      padding: '12px 11px 12px 16px'
								      }}>
									<Box>
										{/*<span /!*className={fileNameClass} onClick={() => onFileClick(index)}*!/>*/}
										<span style={{ paddingLeft: 0, cursor: 'pointer', color: gecinaBlueMain }}>
											{field}
										</span>
									</Box>
									{
										loadingDelete && deleteIndex === index ?
											<Box sx={{ justifyContent: 'flex-end' }}>
												<Loader size={16} />
											</Box>
											:
											<Grid container item
											      onClick={() => onFileDelete(index)}
											      style={{
												      flexDirection: 'row',
												      flexWrap: 'nowrap',
												      justifyContent: 'flex-end',
												      alignItems: 'center',
												      cursor: 'pointer'
											      }}
											>
												<span style={{ paddingLeft: 0, color: gecinaBlueMain }}>
													Supp.
												</span>
												<span style={{
													padding: '0px 2px',
													marginLeft: 15
												}}>
													<SvgTrash color={WHITE} secondColor={gecinaBlueMain} />
												</span>
											</Grid>
									}
								</Grid>
							))
						:
						// <Grid container alignItems="center" className={classes.noFileContainer}>
						<Grid container sx={{ alignItems: 'center', backgroundColor: gecinaBrown10 }}>
							{/*{
								submitFailed && error &&
								<span className={classes.errorStyle}>{error}</span>
							}*/}
						</Grid>
				}
			</Grid>
		</Grid>
	)
}

export default CustomFormFile
