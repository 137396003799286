// Enumération des champs du formulaire pour filtrer les procédures
export enum filtresListeProceduresFieldsEnum {
	nomProcedure = 'nomProcedure',
	statut = 'statut',
	creeLe = 'creeLe',
	avantLe = 'avantLe'
}

// Enumération des statuts des procédures
export enum statutListeProceduresEnum {
	all = 'Tous',
	draft = 'Brouillon',
	active = 'En cours',
	finished = 'Terminée',
	expired = 'Expirée',
	refused = 'Refusée'
}

// Enumération des clés du POST des filtres de récupération de la liste des procédures
export enum filtresListeProceduresPostEnum {
	seeAll = 'seeAll',
	name = 'name',
	status = 'status',
	before = 'before',
	after = 'after',
	page = 'page'
}

// Enum pour les clés de la réponse de la récupération de la liste des procédures
export enum filtresListeProceduresResponseEnum {
	list = 'list',

	// Éléments dans l'objet "list"
	name = 'name',
	date_creation = 'date_creation',
	date_expiration = 'date_expiration',
	status = 'status',
	signers = 'signers',

	// Éléments dans l'objet "signers" et "subscribers"
	proof = 'proof',
	firstName = 'firstName',
	lastName = 'lastName',
	mobilePhone = 'mobilePhone',
	email = 'email',

	subscribers = 'subscribers',
	files = 'files',

	// Éléments dans l'objet "files"
	id = 'id',
	type = 'type',

	total = 'total',
	page = 'page',

	// Éléments dans l'objet "page"
	current = 'current',
	last = 'last'
}

export enum enteteTableauAffichageListeEnum {
	name = 'Nom de la procédure',
	date_creation = 'Création',
	date_expiration = 'Expiration',
	status = 'Statut'
}
