import { Box, Grid } from '@mui/material'
import SvgClose from '../../../../../icons/SvgClose'
import { gecinaBlueMain, gecinaBrownMain } from '../../../../../theme'
import { ongletsAjoutSignataire } from '../../../types/ajoutSignatairePopinModel'
import React, { Dispatch, SetStateAction } from 'react'

interface AcceptingProps {
	ongletActif: string,
	setOngletActif: Dispatch<SetStateAction<string>>,
	onCloseDialog: () => void
}

type EntetePopinAjoutSignataireProps = AcceptingProps

const EntetePopinAjoutSignataire: React.FC<EntetePopinAjoutSignataireProps> = ({ ongletActif, setOngletActif, onCloseDialog }) => {


	return (
		<>
			<Grid container sx={{ height: 65, mt: '40px', mb: '20px' }}>
				<Box sx={{
					width: 681,
					height: 25,
					lineHeight: '25px',
					color: '#122941',
					flex: 'none',
					order: 0,
					flexGrow: 1,
					fontFamily: 'BlackerDisplay', fontSize: 22, fontWeight: 900
				}}>
					Ajouter un signataire
				</Box>
				<Box sx={{
					height: 25,
					flex: 'none',
					order: 1,
					cursor: 'pointer',
					flexGrow: 1
				}} onClick={onCloseDialog}>
					<SvgClose color={gecinaBlueMain} />
				</Box>
			</Grid>
			<Grid container sx={{
				height: 60, placeContent: 'space-evenly', alignSelf: 'center', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 700, display: 'flex', flexDirection: 'row', position: 'relative',
				'&::after': {
					top: '40px',
					width: '100%',
					height: '1px',
					content: '\' \'',
					display: 'inline-block',
					background: '#C4C4C4',
					position: 'absolute',
					right: 0
				}
			}}>
				<Box onClick={() => {
					setOngletActif(ongletsAjoutSignataire.interne)
				}}
				     sx={{
					     marginLeft: '40px', color: gecinaBlueMain, height: '100%', position: 'relative',
					     '&:hover': {
						     cursor: 'pointer'
					     },
					     '&:active': {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '36px',
							     width: 200,
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     left: -60,
							     right: 0
						     }
					     },
					     ...(ongletActif === ongletsAjoutSignataire.interne) && {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '36px',
							     width: 200,
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     left: -60,
							     right: 0
						     }
					     }
				     }}>
					Interne
				</Box>
				<Box onClick={() => {
					setOngletActif(ongletsAjoutSignataire.externe)
				}}
				     sx={{
					     marginLeft: '40px', color: gecinaBlueMain, height: '100%', position: 'relative',
					     '&:hover': {
						     cursor: 'pointer'
					     },
					     '&:active': {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '36px',
							     width: 200,
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     left: -60,
							     right: 0
						     }
					     },
					     ...(ongletActif === ongletsAjoutSignataire.externe) && {
						     color: gecinaBrownMain,
						     '&::after': {
							     top: '36px',
							     width: 200,
							     height: 4,
							     content: '\' \'',
							     display: 'inline-block',
							     background: gecinaBrownMain,
							     position: 'absolute',
							     left: -60,
							     right: 0
						     }
					     }
				     }}>
					Externe
				</Box>
			</Grid>
		</>
	)
}

export default EntetePopinAjoutSignataire