import React from 'react'
import ErrorComponent from '../fields/ErrorComponent'
import { Control, useController } from 'react-hook-form'

type ErrorMessageProps = {
	control: Control<any>
	name: string
}

const ErrorMessage = ({ control, name }: ErrorMessageProps) => {
	const { fieldState: { error } } = useController({ control, name })

	return !!error ?
		(
			<ErrorComponent>
				{error.message && error.message}
			</ErrorComponent>
		) : (
			<></>
		)
}

export default ErrorMessage
