import { champsSignataireEnum, champsSuiveurEnum } from '../enums/champsSignataireEnum'
import { typeSignataire } from '../../../types/ajoutSignatairePopinModel'

export const INITIAL_VALEURS_FORMULAIRE = {
	[champsSignataireEnum.recherche]: '',
	[champsSignataireEnum.nom]: '',
	[champsSignataireEnum.prenom]: '',
	[champsSignataireEnum.telephone]: '',
	[champsSignataireEnum.courriel]: '',
	[champsSignataireEnum.type]: typeSignataire.Signataire
}

export const INITIAL_VALEURS_FORMULAIRE_SUIVEUR = {
	[champsSuiveurEnum.recherche]: '',
	[champsSuiveurEnum.nom]: '',
	[champsSuiveurEnum.prenom]: '',
	[champsSuiveurEnum.courriel]: ''
}
