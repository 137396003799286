import React, { createContext, useEffect, useState } from 'react'
import EnteteCreationProcedure from './components/enteteCreationProcedure'
import { Box, Grid } from '@mui/material'
import { CreationProcedureContextModel, CreationProcedureModel, INITIAL_CREATION_PROCEDURE } from './types/CreationProcedureModel'
import { gecinaBlueMain, gecinaBrownMain, WHITE } from '../../theme'
import CustomTextField from '../../components/fields/CustomTextField'
import { creationProceduresFieldsEnum } from './enums/creationProcedureEnum'
import { FormProvider, useForm } from 'react-hook-form'
import FormDatePickerField from '../../components/fields/FormDatePickerField'
import { annulerProcedure, creerProcedure, fetchUuid } from './api/creationProcadureApi'
import {
	INITIAL_AJOUT_SIGNATAIRE_POPIN,
	INITIAL_AJOUT_SUIVEUR_POPIN,
	INITIAL_RETOUR_CREATION_POPIN,
	PopinModel,
	typeSignataire
} from './types/ajoutSignatairePopinModel'
import PopinAjoutSignataire from './components/popinAjoutSignataire/PopinAjoutSignataire'
import ListeSignataires from './components/listeSignataires'
import { buttonTypesEnum } from '../../components/button/enums/buttonTypesEnum'
import Button from '../../components/button/Button'
import ListeSuiveurs from './components/listeSuiveurs'
import CustomFormFile from '../../components/fields/CustomFormFile'
import { useNavigate } from 'react-router-dom'
import liens from '../../enum/liens'
import PopinRetourCreation from './components/popinRetourCreation'
import PopinAjoutSuiveur from './components/popinAjoutSignataire/PopinAjoutSuiveur'
import HttpStatusCode from '../../enum/httpStatusCode'
import { fileTypeEnum } from '../../enum/procedure/fileEnum'
import ErrorMessage from '../../components/error/ErrorMessage'
import { champsSignataireEnum } from './components/popinAjoutSignataire/enums/champsSignataireEnum'

const POPIN_SIGNATAIRE_ID = 'ajout_signataire'
const POPIN_SUIVEUR_ID = 'ajout_suiveur'
const POPIN_RETOUR_ID = 'retour_creation'

export const CreationProcedureContext = createContext<CreationProcedureContextModel>({
	formValues: INITIAL_CREATION_PROCEDURE,
	setFormValues: () => {
	}
})

const CreationProcedure = () => {
	const methods = useForm<CreationProcedureModel>({
		defaultValues: INITIAL_CREATION_PROCEDURE
	})
	const { register, handleSubmit, reset, setError, clearErrors, control } = methods
	const navigate = useNavigate()
	const [formValues, setFormValues] = useState<CreationProcedureModel>(INITIAL_CREATION_PROCEDURE)
	const [signatairePopinOuverte, setSignatairePopinOuverte] =
		useState<PopinModel>(INITIAL_AJOUT_SIGNATAIRE_POPIN)
	const [suiveurPopinOuverte, setSuiveurPopinOuverte] =
		useState<PopinModel>(INITIAL_AJOUT_SUIVEUR_POPIN)
	const [retourCreationPopinOuverte, setRetourCreationPopinOuverte] =
		useState<PopinModel>(INITIAL_RETOUR_CREATION_POPIN)
	const [retourCreationProcedure, setRetourCreationProcedure] = useState<{ message: string, statut: number }>({ message: '', statut: 0 })
	const [loading, setLoading] = useState<boolean>(false)

	// On a besoin d'un uuid du front pour stocker les documents
	// Si on en a déja un pas besoin, sinon on le récup du back.
	// Bien penser a le supprimer a la soumisson du formulaire.
	useEffect(() => {
		let localUuid = localStorage.getItem('uuid')
		if (localUuid && localUuid !== 'null') {
			setFormValues({ ...formValues, uuid: localUuid })
		} else {
			setLoading(true)
			fetchUuid()
				.then((res) => {
					localUuid = res.uuid
					setFormValues({ ...formValues, uuid: localUuid })
					localStorage.setItem('uuid', localUuid!)
				})
			setLoading(false)
		}
	}, [])

	const onSubmit = (value: CreationProcedureModel) => {
		clearErrors(creationProceduresFieldsEnum.signataires)
		//check date expiration
		const dateMinExp = new Date()
		dateMinExp.setDate(dateMinExp.getDate() + 1)
		if (value.expirationDate === null || value.expirationDate < dateMinExp ||
			!formValues.signers.length || !formValues.signable_files.length) {
			if (!(formValues.signers.filter((signer) => signer[champsSignataireEnum.type] === typeSignataire.Signataire)).length) {
				setError(creationProceduresFieldsEnum.signataires, { type: 'custom', message: 'Veuillez ajouter un signataire à la procédure' })
			}
			if (!formValues.signable_files.length) {
				setError(creationProceduresFieldsEnum.documents_a_signer, { type: 'custom', message: 'Veuillez ajouter un document à signer à la procédure' })
			}
			if (value.expirationDate === null || value.expirationDate < dateMinExp){
				setError(creationProceduresFieldsEnum.date_expiration, { type: 'custom', message: 'Veuillez saisir une date d\'expiration plus tard que demain' })
			}
		} else {
			setFormValues({ ...formValues, name: value.name, expirationDate: value.expirationDate })
			setLoading(true)
			return creerProcedure({ ...formValues, name: value.name, expirationDate: value.expirationDate })
				.then((response) => {
					setRetourCreationProcedure({ message: '', statut: response.status })
					//Supprime l'uuid du localStorage uniquement si la création a réussi
					localStorage.removeItem('uuid')
				})
				.catch((error) => {
					setRetourCreationProcedure({ message: error.response.data, statut: error.response.status })
					setLoading(false)
				})
				.finally(() => {
					setRetourCreationPopinOuverte({ open: true, id: POPIN_RETOUR_ID })
					setLoading(false)
				})

		}
	}

	const onCloseDialogRetourCreation = () => {
		setRetourCreationPopinOuverte(INITIAL_RETOUR_CREATION_POPIN)
		if (retourCreationProcedure.statut === HttpStatusCode.OK) {
			navigate(liens.listeProcedure, { state: { rafraichir: true } })
		}
	}

	return (
		<Box>
			<CreationProcedureContext.Provider
				value={{
					formValues,
					setFormValues
				}}
			>
				<EnteteCreationProcedure />
				<Grid container sx={{ paddingX: '172px', zIndex: -1 }}>
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid container sx={{ mt: '40px' }}>
								<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: '22px', fontWeight: 900, color: gecinaBlueMain }}>
									Paramètres
								</Box>
								<Grid mt="22px" flexDirection="row" display="flex" width="100%" alignItems="center">
									<Box sx={{ width: '345px', color: gecinaBlueMain, minHeight: '81px' }}>
										<CustomTextField register={register(creationProceduresFieldsEnum.nom, {
											required: 'Champ requis'
										})}
										                 id="procedure_nom"
										                 label="Nom de la procédure*"
										/>
									</Box>
									<Box sx={{ width: '345px', ml: '15px', color: gecinaBlueMain, minHeight: '81px' }}>
										<FormDatePickerField
											register={register(creationProceduresFieldsEnum.date_expiration, {
												valueAsDate: true
											})}
											id={creationProceduresFieldsEnum.date_expiration}
											label="Date d'expiration"
											minDate
										/>
									</Box>
								</Grid>
							</Grid>

							{/* Bloc de gestion des signataires */}
							<Grid container sx={{ mt: '40px' }}>
								<Grid item>
									<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: '22px', fontWeight: 900, color: gecinaBlueMain }}>
										Signataires et Validateurs
									</Box>
									<Box>
										<ErrorMessage control={control} name={creationProceduresFieldsEnum.signataires} />
									</Box>
									<Box sx={{ width: 1096 }}>
										<ListeSignataires />
									</Box>
									<Button
										sx={{
											mt: '20px',
											height: '50px',
											p: 0,
											width: '204px',
											fontWeight: 600,
											lineHeight: '18.29px'
										}}
										bgcolor="#ffffff00"
										color={gecinaBlueMain}
										border={true}
										disabled={false}
										type={buttonTypesEnum.button}
										onClick={() => {
											clearErrors(creationProceduresFieldsEnum.signataires)
											setSignatairePopinOuverte({ open: true, id: POPIN_SIGNATAIRE_ID })
										}}
									>
										Ajouter un signataire
									</Button>
								</Grid>
							</Grid>

							{/* Bloc de gestion des suiveurs */}
							<Grid container sx={{ mt: '40px' }}>
								<Grid item>
									<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: '22px', fontWeight: 900, color: gecinaBlueMain }}>
										Suiveurs
									</Box>
									<Box sx={{ width: 1096 }}>
										<ListeSuiveurs />
									</Box>
									<Button
										sx={{
											mt: '20px',
											height: '50px',
											p: 0,
											width: '204px',
											fontWeight: 600,
											lineHeight: '18.29px'
										}}
										bgcolor="#ffffff00"
										color={gecinaBlueMain}
										border={true}
										disabled={false}
										type={buttonTypesEnum.button}
										onClick={() => setSuiveurPopinOuverte({ open: true, id: POPIN_SUIVEUR_ID })}
									>
										Ajouter un suiveur
									</Button>
								</Grid>
							</Grid>
							<Grid container sx={{ mt: '40px' }}>
								<CustomFormFile
									id={creationProceduresFieldsEnum.documents_a_signer}
									name={creationProceduresFieldsEnum.documents_a_signer}
									control={control}
									label="Documents à signer"
									fileType={fileTypeEnum.signable}
									onChange={(list) => {
										clearErrors(creationProceduresFieldsEnum.documents_a_signer)
										setFormValues({ ...formValues, signable_files: list })
									}}
								/>
							</Grid>
							<Grid container sx={{ mt: '40px' }}>
								<CustomFormFile
									id={creationProceduresFieldsEnum.documents_en_consultation}
									name={creationProceduresFieldsEnum.documents_en_consultation}
									control={control}
									label="Documents en consultation seule"
									fileType={fileTypeEnum.attachment}
									onChange={(list) => {
										clearErrors(creationProceduresFieldsEnum.documents_en_consultation)
										setFormValues({ ...formValues, attachment_files: list })
									}}
								/>
							</Grid>
							<Grid container item justifyContent="flex-end" sx={{ mb: '20px', mt: '10px', ml: 0 }}>
								<Grid item sx={{ maxWidth: 'max-content', minWidth: 'fit-content' }}>
									<Button
										sx={{
											height: '51px',
											p: 0,
											width: '195px',
											fontWeight: 600,
											lineHeight: '18.29px'
										}}
										bgcolor={WHITE}
										color={gecinaBlueMain}
										border={false}
										disabled={false}
										type={buttonTypesEnum.button}
										onClick={() => {
											annulerProcedure({ uuid: formValues.uuid })
											reset(INITIAL_CREATION_PROCEDURE)
											setFormValues(INITIAL_CREATION_PROCEDURE)
											localStorage.removeItem('uuid')
											navigate(liens.listeProcedure, { state: { rafraichir: true } })
										}}
									>
										Annuler
									</Button>
								</Grid>

								<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
									<Button
										sx={{
											height: '51px',
											p: 0,
											width: '195px',
											fontWeight: 600,
											lineHeight: '18.29px'
										}}
										bgcolor={gecinaBlueMain}
										color={gecinaBrownMain}
										border={false}
										disabled={loading}
										type={buttonTypesEnum.submit}
									>
										Créer
									</Button>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</Grid>
				<PopinAjoutSignataire open={signatairePopinOuverte.open} onCloseDialog={() => setSignatairePopinOuverte(INITIAL_AJOUT_SIGNATAIRE_POPIN)} />
				<PopinAjoutSuiveur open={suiveurPopinOuverte.open} onCloseDialog={() => setSuiveurPopinOuverte(INITIAL_AJOUT_SUIVEUR_POPIN)} />
				<PopinRetourCreation open={retourCreationPopinOuverte.open} onCloseDialog={onCloseDialogRetourCreation} response={retourCreationProcedure} />
			</CreationProcedureContext.Provider>
		</Box>
	)
}

export default CreationProcedure