// Enumération des champs du formulaire pour ajouter un signataire
export enum champsSignataireEnum {
	recherche = 'recherche',
	nom = 'nom',
	prenom = 'prenom',
	telephone = 'telephone',
	courriel = 'courriel',
	type = 'type'
}

// Enumération des champs du formulaire pour ajouter un suiveur
export enum champsSuiveurEnum {
	recherche = 'recherche',
	nom = 'nom',
	prenom = 'prenom',
	courriel = 'courriel'
}