import React, { useContext } from 'react'
import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { capitalizeFirstLetter, getKeyFromEnumValue } from '../../../utils/utils'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { HeadRow } from '../../../components/table/types/tableModel'
import { enteteTableauListeSignataireEnum } from '../enums/creationProcedureEnum'
import { SignataireModel } from '../types/CreationProcedureModel'
import { echangerPositionSignataire, mapperSignatairesPourListe } from '../utils/creationProcedureUtils'
import { gecinaBrownMain } from '../../../theme'
import SvgTrash from '../../../icons/SvgTrash'
import SvgFlecheVersBas from '../../../icons/SvgFlecheVersBas'
import SvgFlecheVersHaut from '../../../icons/SvgFlecheVersHaut'
import { CreationProcedureContext } from '../CreationProcedure'


const ListeSignataires = () => {
	const { setFormValues, formValues } = useContext(CreationProcedureContext)

	const entetesTableau: HeadRow[] =
		[
			{
				label: enteteTableauListeSignataireEnum.nom,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.nom)
			},
			{
				label: enteteTableauListeSignataireEnum.prenom,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.prenom)
			},
			{
				label: enteteTableauListeSignataireEnum.telephone,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.telephone)
			},
			{
				label: enteteTableauListeSignataireEnum.courriel,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.courriel)
			},
			{
				label: enteteTableauListeSignataireEnum.type,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.type),
				render: (type: string) => (
					<span>{capitalizeFirstLetter(type)}</span>
				)
			},
			{
				label: enteteTableauListeSignataireEnum.ordre,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.ordre),
				render: (ordre: number) => (
					<>
						<span>{ordre}</span>
						{formValues.signers.length > 1 ?
							ordre === 1 ?
								<SvgFlecheVersBas style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, 0, 1)} />
								:
								ordre === formValues.signers.length ?
									<SvgFlecheVersHaut style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre - 2)} />
									:
									<>
										<SvgFlecheVersBas style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre)} />
										<SvgFlecheVersHaut style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre - 2)} />
									</>
							:
							<></>
						}
					</>
				)
			},
			{
				label: enteteTableauListeSignataireEnum.supprimer,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.supprimer),
				render: (index: number) => (<SvgTrash style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => setFormValues({ ...formValues, signers: formValues.signers.filter((value, indexFilter) => index !== indexFilter) })} />)
			}
		]


	if (formValues.signers.length === 0) {
		return <></>
	}

	return (
		<Box sx={{ width: '100%' }}>
			<CustomTable
				id="listeSignataires"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<SignataireModel>(mapperSignatairesPourListe(formValues.signers), entetesTableau)}
				hasPagination={false}
			/>
		</Box>
	)
}

export default ListeSignataires
