import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgDownload = (
	{
		color,
		width = 36,
		height = 37,
		...props
	}: IconsModel
) => (
	<svg width={ width } height={ height } viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M35.798 17.9375C35.798 27.2901 28.2019 34.875 18.8277 34.875C9.45356 34.875 1.85742 27.2901 1.85742 17.9375C1.85742 8.5849 9.45356 1 18.8277 1C28.2019 1 35.798 8.5849 35.798 17.9375Z"
			stroke="#B3A688"
			strokeWidth="2"
			id="path5079"
			fill="none" />
		<path
			fill={color}
			d="m 12.904205,25.061877 h 12.32717 c 0.1712,-3e-4 0.3362,0.0634 0.4627,0.1785 0.1264,0.1151 0.2052,0.2732 0.2206,0.4434 0.0155,0.1701 -0.0334,0.3398 -0.1371,0.4758 -0.1036,0.1359 -0.2545,0.2282 -0.4229,0.2586 l -0.1233,0.0109 h -12.32717 c -0.17113,3e-4 -0.33619,-0.0633 -0.46265,-0.1784 -0.12647,-0.1151 -0.20519,-0.2733 -0.22065,-0.4434 -0.01546,-0.1701 0.03345,-0.3399 0.1371,-0.4758 0.10365,-0.1359 0.25453,-0.2282 0.42293,-0.2586 l 0.12327,-0.011 h 12.32717 z m 6.04037,-16.3952697 0.1232,-0.01094 c 0.1603,-6e-5 0.3155,0.05601 0.4387,0.15844 0.1231,0.10243 0.2064,0.24472 0.2352,0.4021 l 0.011,0.12305 V 21.359577 l 3.6735,-3.6654 c 0.1133,-0.1133 0.2628,-0.1832 0.4225,-0.1977 0.1597,-0.0145 0.3194,0.0274 0.4513,0.1184 l 0.0959,0.0793 c 0.1132,0.1132 0.1831,0.2626 0.1973,0.422 0.0143,0.1594 -0.0279,0.3187 -0.1192,0.4502 l -0.0795,0.0944 -4.8418,4.8343 c -0.1135,0.1131 -0.2631,0.1828 -0.4228,0.197 -0.1597,0.0143 -0.3193,-0.0279 -0.4511,-0.119 l -0.0958,-0.078 -4.84189,-4.8343 c -0.12133,-0.1206 -0.19302,-0.2822 -0.20094,-0.4529 -0.0079,-0.1707 0.04852,-0.3382 0.15817,-0.4694 0.10966,-0.1313 0.26459,-0.2167 0.43425,-0.2396 0.16967,-0.0229 0.34177,0.0185 0.48238,0.116 l 0.09451,0.0793 3.67352,3.6654 V 9.3392573 c -10e-5,-0.15999 0.0561,-0.31494 0.1587,-0.43785 0.1026,-0.12291 0.2452,-0.20601 0.4029,-0.2348 l 0.1232,-0.01094 z"
			id="path5657" />
	</svg>
)

export default SvgDownload
