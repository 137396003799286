import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../../../components/fields/FormTextField'
import { Grid, MenuItem } from '@mui/material'
import React, { useContext } from 'react'
import { champsSignataireEnum } from '../enums/champsSignataireEnum'
import { INITIAL_VALEURS_FORMULAIRE } from '../types/ajoutSignataireModel'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../../../theme'
import Button from '../../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../../components/button/enums/buttonTypesEnum'
import { SignataireModel } from '../../../types/CreationProcedureModel'
import { CreationProcedureContext } from '../../../CreationProcedure'
import { typeSignataire } from '../../../types/ajoutSignatairePopinModel'
import FormSelectField from '../../../../../components/fields/FormSelectField'

interface AcceptingProps {
	onCloseDialog: () => void
}

type FormSignataireExterneProps = AcceptingProps

const FormSignataireExterne: React.FC<FormSignataireExterneProps> = ({ onCloseDialog }) => {
	const methods = useForm<SignataireModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset, formState: { errors } } = methods
	const { setFormValues, formValues } = useContext(CreationProcedureContext)

	const onSubmit: SubmitHandler<SignataireModel> = (values: SignataireModel) => {
		setFormValues({ ...formValues, signers: [...formValues.signers, { ...values }] })
		reset(INITIAL_VALEURS_FORMULAIRE)
	}


	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid container spacing={2} sx={{ mb: '20px' }}>
					<Grid item xs>
						<TextField
							sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain}`, fontFamily: `${fontFamilyMonserrat} !important`, fontWeight: 500, fontSize: 13 }}
							register={register(champsSignataireEnum.nom, {
								required: 'Champ requis',
								maxLength: {
									value: 50,
									message: 'Le champs ne peut pas dépasser les 50 caractères'
								}
							})}
							errors={errors}
							id={champsSignataireEnum.nom}
							label="Nom*"
							placeholder="Saisir le nom du signataire"
						/>
					</Grid>
					<Grid item xs>
						<TextField
							sx={{ height: 51, width: 345, border: `1px solid ${gecinaBlueMain} !important`, fontFamily: `${fontFamilyMonserrat} !important`, fontWeight: 500, fontSize: 13 }}
							register={register(champsSignataireEnum.prenom, {
								required: 'Champ requis',
								maxLength: {
									value: 50,
									message: 'Le champs ne peut pas dépasser les 50 caractères'
								}
							})}
							errors={errors}
							id={champsSignataireEnum.prenom}
							label="Prénom*"
							placeholder="Saisir le prénom du signataire"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: '20px' }}>
					<Grid item xs>
						<TextField
							sx={{
								height: 51,
								width: 345,
								border: `1px solid ${gecinaBlueMain} !important`,
								fontFamily: `${fontFamilyMonserrat} !important`,
								fontWeight: 500,
								fontSize: 13
							}}
							register={register(champsSignataireEnum.telephone, {
								required: 'Champ requis',
								maxLength: {
									value: 18,
									message: 'Le champs ne peut pas dépasser les 18 caractères'
								}
							})}
							errors={errors}
							id={champsSignataireEnum.telephone}
							label="Téléphone mobile*"
							placeholder="Saisir le téléphone du signataire"
						/>
					</Grid>
					<Grid item xs>
						<TextField
							sx={{
								height: 51,
								width: 345,
								border: `1px solid ${gecinaBlueMain} !important`,
								fontFamily: `${fontFamilyMonserrat} !important`,
								fontWeight: 500,
								fontSize: 13
							}}
							register={register(champsSignataireEnum.courriel, {
								required: 'Champ requis',
								pattern: {
									value: /^(.+)@(.+)$/,
									message: 'Le courriel ne respecte pas le format'
								}
							})}
							errors={errors}
							id={champsSignataireEnum.courriel}
							label="Courriel*"
							placeholder="Saisir le courriel du signataire"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<FormSelectField
							register={register(champsSignataireEnum.type)}
							id={champsSignataireEnum.type}
							label="Type"
							placeholder="Type de signataire"
							sx={{
								height: 51,
								width: 345,
								border: `1px solid ${gecinaBlueMain} !important`,
								opacity: '0.65'
							}}
						>
							{
								Object.keys(typeSignataire).map(
									(type) => (
										<MenuItem value={type} key={type}>
											{typeSignataire[type as keyof typeof typeSignataire]}
										</MenuItem>
									)
								)
							}
						</FormSelectField>
					</Grid>
				</Grid>
				<Grid container item xs justifyContent="flex-end" sx={{ mt: '10px', ml: 0 }} spacing={2}>
					<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={gecinaBlueMain}
							color={gecinaBrownMain}
							border={false}
							disabled={false}
							type={buttonTypesEnum.submit}
						>
							Ajouter
						</Button>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FormSignataireExterne