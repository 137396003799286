import { createContext, useState } from 'react'
import { ListeProceduresContextModel } from '../types/listeProceduresModel'
import { FiltresListeProceduresPostModel, FiltresListeProceduresResponseModel, INITIAL_REPONSE_LISTE_PROCEDURE } from '../types/filtresListeProceduresModel'

export const ListeProceduresContext = createContext<ListeProceduresContextModel>({
	donneesFormulaire: {},
	setDonneesFormulaire: () => {},
	listeProcedures: INITIAL_REPONSE_LISTE_PROCEDURE,
	setListeProcedures: () => {}
})

export const ListeProceduresProvider: React.FC = ({ children }) => {
	const [donneesFormulaire, setDonneesFormulaire] = useState<FiltresListeProceduresPostModel>({})
	const [listeProcedures, setListeProcedures] = useState<FiltresListeProceduresResponseModel>(INITIAL_REPONSE_LISTE_PROCEDURE)

	return (
		<ListeProceduresContext.Provider
			value={{
				donneesFormulaire,
				setDonneesFormulaire,
				listeProcedures,
				setListeProcedures
			}}
		>
			{children}
		</ListeProceduresContext.Provider>
	)
}
