import axios from 'axios'
import liens from '../../../enum/liens'
import { AjoutFichierBackModel, CreationProcedureModel, FetchUUIDModel, SuppressionFichierBackModel } from '../types/CreationProcedureModel'
import { OktaUsersResponseType, ResponseType } from '../../../types/responseType'


export const fetchUuid = (): Promise<FetchUUIDModel> =>
	axios(liens.uuid)
		.then((res) => {
			return res.data
		})

export const sendFile = (infoFichier: AjoutFichierBackModel): Promise<string> => axios.post(liens.ajoutFichier, infoFichier)
	.then((response) => response.data)


export const deleteFile = (infoFichier: SuppressionFichierBackModel): Promise<string> => axios.post(liens.suppressionFichier, infoFichier)
	.then((response) => response.data)

export const creerProcedure = (informations: CreationProcedureModel): Promise<ResponseType> => axios.post(liens.creerProcedure, informations)

export const annulerProcedure = (uuid: { uuid: string }): Promise<ResponseType> => axios.post(liens.annulerProcedure, uuid)

export const listeUtilisateurokta = (informations: string): Promise<OktaUsersResponseType> => axios.post(liens.listeUtilisateurokta, { search: informations })

