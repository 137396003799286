export const getKeyFromEnumValue = <EnumType>(
	enumParsed: { [key: string]: EnumType },
	enumValue: EnumType
) =>
		Object.keys(enumParsed).find(
			(enumParsedKey) => enumParsed[enumParsedKey] === enumValue
		) ?? ''

export const  capitalizeFirstLetter = (string: string) =>{
	return string.charAt(0).toUpperCase() + string.slice(1)
}